import Tag from '@/components/basic/tag';
import Text from '@/components/basic/text';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import useNotificationStore from '@/store/useNotificationStore';
import theme from '@/style';
import React, {useEffect, memo, useRef, useCallback} from 'react';

import {View, ScrollView} from 'react-native';
import {useShallow} from 'zustand/react/shallow';

const NotificationTabs = () => {
  const scrollViewRef = useRef<ScrollView>(null);
  const {messageTypeTabIndex, messageTypeList} = useNotificationStore(
    useShallow(state => ({
      messageTypeTabIndex: state.messageTypeTabIndex,
      messageTypeList: state.messageTypeList,
    })),
  );

  const tabWidths = React.useRef(
    new Array(messageTypeList?.length).fill(0),
  ).current;
  const tabOffsets = React.useRef(
    new Array(messageTypeList?.length).fill(0),
  ).current;

  const scrollViewHandle = useCallback(() => {
    scrollViewRef.current?.scrollTo({
      x: tabOffsets[messageTypeTabIndex],
      animated: true,
    });
  }, [messageTypeTabIndex, tabOffsets]);

  useEffect(() => {
    requestAnimationFrame(() => scrollViewHandle());
  }, [scrollViewHandle]);

  // Animated values
  // const derivedTranslateX = useDerivedValue(() => {
  //   return withTiming(
  //     tabOffsets[messageTypeTabIndex] + tabWidths[messageTypeTabIndex] / 2,
  //   );
  // }, [tabOffsets, tabWidths, messageTypeTabIndex]);

  // const animatedUnderlineStyle = useAnimatedStyle(
  //   () => ({
  //     transform: [{translateX: derivedTranslateX.value || 25}],
  //   }),
  //   [derivedTranslateX],
  // );

  const onPressItem = (index: number) => {
    useNotificationStore.setState({
      messageTypeTabIndex: index,
    });
  };

  return (
    <View style={[theme.borderRadius.m, theme.position.rel]}>
      <ScrollView
        ref={scrollViewRef}
        style={[
          // eslint-disable-next-line react-native/no-inline-styles
          {height: 42, backgroundColor: '#191342'},
        ]}
        // eslint-disable-next-line react-native/no-inline-styles
        contentContainerStyle={{
          alignItems: 'center',
        }}
        horizontal
        showsHorizontalScrollIndicator={false}>
        {messageTypeList?.map((item, index) => (
          <NativeTouchableOpacity
            onPress={() => onPressItem(index)}
            key={item?.id}
            onLayout={event => {
              tabWidths[index] = event.nativeEvent.layout.width;
              tabOffsets[index] = event.nativeEvent.layout.x;
            }}
            style={[theme.position.rel, theme.flex.center, {height: 42}]}>
            <View
              style={[
                theme.flex.center,
                theme.padding.lrl,
                theme.borderRadius.m,
                messageTypeTabIndex === index
                  ? // eslint-disable-next-line react-native/no-inline-styles
                    {
                      backgroundColor: theme.basicColor.primary,
                      height: 30,
                    }
                  : // eslint-disable-next-line react-native/no-inline-styles
                    {
                      height: 30,
                    },
              ]}>
              <Text
                size="medium"
                blod
                numberOfLines={1}
                color={
                  messageTypeTabIndex === index
                    ? theme.fontColor.white
                    : theme.fontColor.purple
                }>
                {item?.messageTypeDataTitle}
              </Text>
            </View>

            {item?.unreadCount > 0 ? (
              <Tag
                type="badge"
                backgroundColor={theme.backgroundColor.accent}
                content={item?.unreadCount}
                style={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                }}
              />
            ) : null}
          </NativeTouchableOpacity>
        ))}
      </ScrollView>
      {/* <Animated.View
        style={[
          // eslint-disable-next-line react-native/no-inline-styles
          {
            position: 'absolute',
            bottom: 0,
            height: 4,
            width: 12,
          },
          theme.background.primary,
          animatedUnderlineStyle,
        ]}
      /> */}
    </View>
  );
};

export default memo(NotificationTabs);
